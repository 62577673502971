<template>
  <CCard>
    <CCardHeader>
      {{!this.isEdit ? $t('Создание календаря') : $t('Редактирование календаря') }}
    </CCardHeader>
    <CCardBody>
      <CForm @submit.prevent="save(model)">
        <CInput
            :description="$t('Его будут видеть подписчики')"
            :label="$t('Название')"
            horizontal
            v-model.lazy="model.name"
            required
        />
        <CTextarea
            :description="$t('Его будут видеть подписчики')"
            :label="$t('Описание')"
            placeholder=""
            horizontal
            rows="9"
            v-model.lazy="model.desc"
            required
        />
        <CInput
            :description="$t('Оставьте пустым для страницы по умолчанию https://evli.in/success.html')"
            :label="$t('Страница после успешной подписки')"
            horizontal
            v-model.lazy="model.success_link"
        />
        <CInput
            :description="$t('На какой url будут отправляться данные вашего подписчика')"
            label="Callback-url"
            horizontal
            v-model.lazy="model.callback_url"
            v-if="model.api_enabled"
        />
        <CRow form class="form-group">
          <CCol tag="label" sm="3" class="col-form-label">
            {{$t('События делятся на категории')}}
          </CCol>
          <CCol sm="9">
            <CSwitch
                class="mr-1"
                color="info"
                :checked.sync="model.categories_enabled"
            />
          </CCol>
        </CRow>
        <CRow form class="form-group">
          <CCol tag="label" sm="3" class="col-form-label">
            {{$t('Подписчику показывается выбор категорий')}}
          </CCol>
          <CCol sm="9">
            <CSwitch
                class="mr-1"
                color="info"
                :checked.sync="model.categories_show"
            />
          </CCol>
        </CRow>
        <CInput
            horizontal
            v-model.lazy="model.id"
            required
            hidden
        />
        <router-link to="/calendar/list"><CButton type="submit" size="sm" color="dark">{{ this.$t('Отмена') }}</CButton></router-link>
        <CButton type="submit" size="sm" color="info">{{!this.isEdit ? $t('Создать') : $t('Обновить') }}</CButton>
      </CForm>
      <notifications group="calendar" />
    </CCardBody>
  </CCard>
</template>

<script>
import axios from '@/modules/axios'

export default {
  name: 'CalendarAdd',
  components: {
  },
  data () {
    return {
      model: {
        id: 0,
        name: '',
        desc: '',
        categories_enabled: false,
        categories_show: false,
        api_enabled: false,
        success_link: '',
        callback_url: ''
      }
    }
  },
  created() {
    this.getCalendar()
  },
  computed: {
    isEdit () {
      return this.$route.params.id && parseInt(this.$route.params.id) > 0
    }
  },
  methods: {
    save() {
      const url = this.isEdit ? '/calendar/edit' : '/calendar/add'
      axios.post(url, this.model, {
        headers: {'content-type': 'application/json'}
      }).then(() => {
        this.$router.push('/calendar/list')
      }).catch((error) => {
        this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
      })
    },
    getCalendar () {
      if(!this.isEdit) return
      axios.get('/calendar/info', {
        params: {id: this.$route.params.id}
      }).then((res) => {
        this.model = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
  }
}
</script>
<style lang="scss">
form button {
  margin-right: 2em;
}
</style>
